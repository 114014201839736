export default {
  computed: {
    existing() {
      return this.$store.getters['getCurrentItems']
    }
  },
  data() {
    return {
      available_months: []
    }
  },
  methods: {
    // Duplicate
    setDuplicate(months, no_submit) {
      this.form.id = 0
      this.form.month_id = months.length ? months[0].id : ''
      this.form.duplicate = true

      if (!no_submit) {
        this.submit()
      }
    },
    // Available months
    setAvailableMonths(parameter, duplicated) {
      const existing = []
      if (this.existing && this.existing.name === parameter && this.existing.items.length) {
        this.existing.items.forEach(item => {
          if (this.form.id !== item.id) existing.push(item.month_id)
        })
      }
      this.available_months = this.lists.budget_months.filter(month => month.id >= this.property._periods?.FAC.month_id && existing.indexOf(month.id) === -1)
      if (!duplicated && this.form.id) {
        this.available_months.push(this.lists.budget_months.find(month => month.id === this.form.month_id))
      }
    }
  }
}
